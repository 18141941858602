import { Component, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'toro-language-banner',
  standalone: true,
  imports: [],
  templateUrl: './language-banner.component.html',
  styleUrl: './language-banner.component.scss'
})
export class LanguageBannerComponent {
  #translocoService = inject(TranslocoService)

  setActiveLanguage(lang: string) {
    this.#translocoService.setActiveLang(lang)
  }
}
