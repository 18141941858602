import { Component, inject } from '@angular/core';
import { LanguageBannerComponent } from '../language-banner/language-banner.component';
import { Router, RouterLink } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'toro-burger-menu',
  standalone: true,
  imports: [
    LanguageBannerComponent,
    TranslocoModule
  ],
  templateUrl: './burger-menu.component.html',
  styleUrl: './burger-menu.component.scss'
})
export class BurgerMenuComponent {
  isMenuOpen = false;

  #router = inject(Router)

  openMenu() {
    this.isMenuOpen = true;
  }

  closeMenu() {
    this.isMenuOpen = false;
  }

  scrollToSection(section: string) {
    document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
    this.closeMenu();
  }

  goTo(page: string) {
    this.#router.navigateByUrl(`${page}`);
  }
}
