<div class="footer-container">
  <div class="left">
    <p>©&nbsp; Toro Studio</p>
    <p><a href="https://sp-it.be" target="_blank">SPIT</a>&nbsp;-&nbsp;2024</p>
  </div>

  <div class="right">
    <ul>
      <li><a href="https://x.com/Toro_StudioAnim" target="_blank">Twitter</a></li>
      <li><a href="https://www.instagram.com/toro_animation_studio/" target="_blank">Instagram</a></li>
    </ul>
  </div>
</div>
