<div>
  <img src="assets/img/Toro_Studio_LogoBlack.png" alt="Logo" (click)="goTo('/')">
  <div class="right-content">
    <toro-language-banner/>
    <div (click)="openMenu()"><p>食</p></div>
    @if (isMenuOpen) {
      <div id="overlayMenu" class="overlay">
        <div class="menu-content">
          <span class="close-btn" (click)="closeMenu()">&times;</span>
          <a href="#home" (click)="scrollToSection('home')">{{ 'titles.home' | transloco }}</a>
          <a href="#work" (click)="scrollToSection('work')">{{ 'titles.works' | transloco }}</a>
          <a href="#contact" (click)="scrollToSection('contact')">{{ 'titles.contact' | transloco }}</a>
        </div>
      </div>
    }
  </div>
</div>
